export enum EScreenLayout {
  CONTAIN = 1,
  COVER = 2,
  HALF_SCREEN = 3,
  PIP = 4,
  CINEMA = 5,
  THUMBNAILS = 6,
}

export const OPTIONS_SCREEN_LAYOUT = {
  [EScreenLayout.CONTAIN]: {
    class: 'contain',
    limit: 6,
  },
  [EScreenLayout.COVER]: {
    class: 'cover',
    limit: 6,
  },
  [EScreenLayout.HALF_SCREEN]: {
    class: 'half-screen',
    limit: 5,
  },
  [EScreenLayout.PIP]: {
    class: 'pip',
    limit: 6,
  },
  [EScreenLayout.CINEMA]: {
    class: 'cinema',
    limit: 6,
  },
  [EScreenLayout.THUMBNAILS]: {
    class: 'thumbnails',
    limit: 6,
  },
};
